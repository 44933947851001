.chat-online {
	color: lighten($success, 10%);
}

.chat-offline {
	color: lighten($danger, 10%);
}

.chat-messages {
	display: flex;
	flex-direction: column;
	max-height: 800px;
	overflow-y: scroll;
}

.chat-message-left,
.chat-message-right {
	display: flex;
	flex-shrink: 0;
}

.chat-message-left {
	margin-right: auto;
}

.chat-message-right {
	flex-direction: row-reverse;
	margin-left: auto;
}
