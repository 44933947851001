.icon {
	width: 60px;
	height: 60px;
	line-height: 52px;
	text-align: center;
	font-size: 2rem;
	margin: 0.5rem 0;

	svg {
		width: 32px;
		height: 32px;
	}
}

@each $color, $value in $theme-colors {
	.icon-#{$color} {
		background-color: $value;
	}
}
