/* cspell:ignore dropup */

html,
body {
	background-color: #f4f7fc !important;
}

/**------------------------------------------------------
 * Body Style to Set Always on top
 * ------------------------------------------------------
 */
html {
	position: relative;
}
body {
	position: absolute !important;
	top: 0 !important;
	width: 100% !important;

	// The issue can visible in dark mode
	height: initial !important;
	overflow: initial !important;
}

.content {
	background-color: transparent !important;
}

.btn {
	box-shadow: none !important;
}

strong {
	font-weight: bold !important;
}

%no-user-select {
	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
}

.product-search {
	color: #000; // fallback for older browsers
	color: rgba(0, 0, 0, 0.4);

	font-size: 8pt;
	font-family: Arial, sans-serif;
}

.no-selection {
	// makes the text not selected on mouse click
	@extend %no-user-select;
}

.no-selection::selection {
	background: transparent;
}

*:focus {
	outline: none !important;
}

// targeted css for ngb-dropdown icon
.dropdown {
	.dropdown-toggle:after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f078';
		border: none !important;
		transform: none;
		font-weight: 500;
		font-size: smaller;
	}
}

.dropup {
	.dropdown-toggle:after {
		font-family: 'Font Awesome 5 Pro';
		content: '\f078';
		border: none !important;
		transform: rotate(180deg);
		font-weight: 500;
		font-size: smaller;
	}
}

/**------------------------------------------------------
 * Neutral Buttons
 */
.btn-neutral {
	background-color: var(--light-gray);
	border-color: var(--light-gray);
}

.btn-outline-neutral {
	color: var(--gray);
	border-color: var(--light-gray);
}

.btn-outline-neutral:hover {
	background-color: var(--light-gray);
	border-color: var(--light-gray);
}

/**------------------------------------------------------
 * Smooth Fading Effect
 * --------------------
 * > used to avoid a hard cut on the bottom of a list
 * > source : https://stackoverflow.com/questions/22666063/how-to-fade-the-edge-of-a-div-with-just-css
 */
.fade-top {
	top: 0px;
	width: 100%;
	height: 20px;
	display: block;
	z-index: 9;
	position: absolute;
	background-image: linear-gradient(
		to top,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1) 100%
	);
}

.fade-bottom {
	width: 100%;
	height: 20px;
	bottom: 0px;
	display: block;
	position: absolute;
	background-image: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1) 100%
	);
}

/**------------------------------------------------------
 * Hide Google Translate
 * ------------------------------------------------------
 */
#google_translate_element {
	display: none;
}

/**------------------------------------------------------
 * Input Placeholders
 * ------------------------------------------------------
 */
::placeholder {
	opacity: 0.5 !important;
}

/**------------------------------------------------------
 * Custom Dropdown
 */
.table-options-dropdown > .dropdown-menu {
	width: 300px !important;
}
