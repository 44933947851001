.ng-wizard-theme-arrows {
	border: 1px solid #dddddd;
	border-radius: 5px;
}

.ng-wizard-theme-arrows > .ng-wizard-container {
	min-height: 200px;
}

.ng-wizard-theme-arrows .step-content {
	border: 0 solid #d4d4d4;
	padding: 0 10px;
	text-align: left;
	background-color: #ffffff;
}

.ng-wizard-theme-arrows .ng-wizard-toolbar {
	padding: 10px;
	margin-bottom: 0 !important;
}

.ng-wizard-theme-arrows > ul.step-anchor {
	border: 0;
	padding: 0;
	overflow: hidden;
	list-style: none;
	background: #f5f5f5;
	border-bottom: 1px solid #dddddd;
	border-radius: 0;
	border-top-right-radius: 5px;
}

.ng-wizard-theme-arrows > ul.step-anchor li + li::before {
	padding: 0;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a,
.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
	color: #bbbbbb;
	padding: 10px 0 10px 45px;
	position: relative;
	display: block;
	border: 0 !important;
	border-radius: 0;
	outline-style: none;
	background: #f5f5f5;
	text-decoration: none;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a::after {
	content: ' ';
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;
	border-bottom: 50px solid transparent;
	border-left: 30px solid #f5f5f5;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	left: 100%;
	z-index: 2;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a::before {
	content: ' ';
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent;

	/* Go big on the size, and let overflow hide */
	border-bottom: 50px solid transparent;
	border-left: 30px solid #dddddd;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	margin-left: 1px;
	left: 100%;
	z-index: 1;
}

.ng-wizard-theme-arrows > ul.step-anchor > li:first-child > a {
	padding-left: 15px;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
	color: #bbbbbb;
	text-decoration: none;
	outline-style: none;
	background: #f5f5f5;
	border-color: #f5f5f5;
}

.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover::after {
	border-left-color: #f5f5f5;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.clickable > a:hover {
	color: #4285f4 !important;
	background: #46b8da !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a {
	border-color: #5cb85c !important;
	color: #ffffff !important;
	background: #5cb85c !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.active > a::after {
	border-left: 30px solid #5cb85c !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a {
	border-color: #b1dfbb !important;

	/*  #5cb85c */
	color: #ffffff !important;
	background: #b1dfbb !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.done > a::after {
	border-left: 30px solid #b1dfbb;

	/* c3e6cb */
}

.ng-wizard-theme-arrows > ul.step-anchor > li.danger > a {
	border-color: #d9534f !important;
	color: #ffffff !important;
	background: #d9534f !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.danger > a::after {
	border-left: 30px solid #d9534f !important;
}

.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a,
.ng-wizard-theme-arrows > ul.step-anchor > li.disabled > a:hover {
	color: #eeeeee !important;
}

/* Responsive CSS */
@media screen and (max-width: 768px) {
	.ng-wizard-theme-arrows > ul.step-anchor {
		border: 0;
		background: #dddddd !important;
	}

	.ng-wizard-theme-arrows > .nav-tabs > li {
		float: none !important;
		margin-bottom: 0;
	}

	.ng-wizard-theme-arrows > ul.step-anchor > li > a,
	.ng-wizard-theme-arrows > ul.step-anchor > li > a:hover {
		padding-left: 15px;
		margin-right: 0;
		margin-bottom: 1px;
	}

	.ng-wizard-theme-arrows > ul.step-anchor > li > a::after,
	.ng-wizard-theme-arrows > ul.step-anchor > li > a::before {
		display: none;
	}
}

/* Loader Custom Style */
.ng-wizard-theme-arrows::before {
	border: 10px solid #f3f3f3;
	border-top: 10px solid #5cb85c;
}
