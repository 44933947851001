// Additional Grids (used for tables, ...)
.col-0-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 4.1666665%;
	flex: 0 0 4.1666665%;
	max-width: 4.1666665%;
}

.col-1-3 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 10.8333329%;
	flex: 0 0 10.8333329%;
	max-width: 10.8333329%;
}

.col-1-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 12.4999995%;
	flex: 0 0 12.4999995%;
	max-width: 12.4999995%;
}

.col-2-2 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 18.3333326%;
	flex: 0 0 18.3333326%;
	max-width: 18.3333326%;
}

.col-2-4 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 19.9999992%;
	flex: 0 0 19.9999992%;
	max-width: 19.9999992%;
}

.col-2-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 20.8333325%;
	flex: 0 0 20.8333325%;
	max-width: 20.8333325%;
}

.col-3-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 29.1666655%;
	flex: 0 0 29.1666655%;
	max-width: 29.1666655%;
}

.col-4-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 37.4999985%;
	flex: 0 0 37.4999985%;
	max-width: 37.4999985%;
}

.col-5-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 45.8333315%;
	flex: 0 0 45.8333315%;
	max-width: 45.8333315%;
}

.col-6-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 54.1666645%;
	flex: 0 0 54.1666645%;
	max-width: 54.1666645%;
}

.col-7-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 62.5%;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.col-8-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 70.8333305%;
	flex: 0 0 70.8333305%;
	max-width: 70.8333305%;
}

.col-9-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 79.1666635%;
	flex: 0 0 79.1666635%;
	max-width: 79.1666635%;
}

.col-10-5 {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	-ms-flex: 0 0 87.5%;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}
