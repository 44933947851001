.wrapper {
	align-items: stretch;
	display: flex;
	width: 100%;
	position: relative;
	z-index: 0;
}

.wrapper-header {
	width: 100%;
	height: 264px;
	position: absolute;
	top: 0;
	left: 0;
	background: $wrapper-bg;
	z-index: -1;
}
