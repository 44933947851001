/* cspell:ignore hindvadodara */

//0 - Fonts
@import '6-fonts/hindvadodara/hindvadodara';
@import '6-fonts/jost/jost';

//1 - Override variables
$blue: #3b7ddd !default;
$body-color: #212529 !default;
$border-radius: 0.5rem !default;
$headings-color: #6e6e6e !default;
$card-spacer-y: 0.75rem !default;
$sidebar-brand-padding: 0.5rem !default;

//2 - Theme variables
@import '1-variables/app';

//3 - Bootstrap
@import '../../../../../../node_modules/bootstrap/scss/bootstrap';

//4 - Theme mixins
@import '2-mixins/background';
@import '2-mixins/button';
@import '2-mixins/modal';
@import '2-mixins/placeholder';
@import '2-mixins/switch';
@import '2-mixins/tabs';
@import '2-mixins/wizard';
@import '2-mixins/breakpoints';

//5 - Theme components
@import '3-components/accordion';
@import '3-components/alert';
@import '3-components/avatar';
@import '3-components/badge';
@import '3-components/breadcrumb';
@import '3-components/buttons';
@import '3-components/card';
@import '3-components/chart';
@import '3-components/chat';
@import '3-components/collapse';
@import '3-components/content';
@import '3-components/dropdown';
@import '3-components/feather';
@import '3-components/footer';
@import '3-components/forms';
@import '3-components/grid';
@import '3-components/hamburger';
@import '3-components/header';
@import '3-components/icon';
@import '3-components/landing';
@import '3-components/main';
@import '3-components/modal';
@import '3-components/nav';
@import '3-components/navbar';
@import '3-components/reboot';
@import '3-components/sidebar';
@import '3-components/splash';
@import '3-components/tables';
@import '3-components/tabs';
@import '3-components/type';
@import '3-components/wrapper';
@import '3-components/wizard';

//6 - Theme utilities
@import '4-utilities/background';
@import '4-utilities/cursors';
@import '4-utilities/overflow';
@import '4-utilities/position';
@import '4-utilities/sizing';

//7 - custom
@import '5-custom/advanced';
@import '5-custom/badge';
@import '5-custom/dialogs';
@import '5-custom/disable';
@import '5-custom/effects';
@import '5-custom/forms';
@import '5-custom/general';
@import '5-custom/grids';
@import '5-custom/scrollbar';
@import '5-custom/theme';
@import '5-custom/charts';
@import '5-custom/layout';
@import '5-custom/tables';
@import '5-custom/wizard';
@import '5-custom/bootstrap_4';
