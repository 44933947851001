.main {
	position: relative;
	width: 100%;
	min-height: 100vh;
	min-width: 0;
	margin-left: $sidebar-width;
	transition: $sidebar-transition;

	@include media-breakpoint-down(lg) {
		margin-left: 0;
		overflow-y: hidden;
		width: 100%;
		min-width: 100%;
	}
}

.main {
	&.toggled {
		margin-left: 0;
		width: 100%;

		@include media-breakpoint-down(lg) {
			width: 100%;
			min-height: 100vh;
			min-width: 0;
			margin-left: $sidebar-width;
			transition: $sidebar-transition;
		}
	}
}
