.cursor-grab {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

.cursor-pointer {
	cursor: pointer;
}
