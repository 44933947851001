/* gujarati */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(neINzCKvrIcn5pbuuuriV9tTQInVrFkcrSuywA.woff2) format('woff2');
	unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC,
		U+A830-A839;
}
/* latin-ext */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(neINzCKvrIcn5pbuuuriV9tTQJzVrFkcrSuywA.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(neINzCKvrIcn5pbuuuriV9tTQJLVrFkcrSs.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
/* gujarati */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(neIQzCKvrIcn5pbuuuriV9tTSGH2uW8-oAGIyY0Wfw.woff2) format('woff2');
	unicode-range: U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9, U+25CC,
		U+A830-A839;
}
/* latin-ext */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(neIQzCKvrIcn5pbuuuriV9tTSGH2uXo-oAGIyY0Wfw.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
		U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Hind Vadodara';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(neIQzCKvrIcn5pbuuuriV9tTSGH2uXQ-oAGIyY0.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
		U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
}
