.splash {
	pointer-events: none;
	user-select: none;

	.splash-icon {
		background: $splash-bg;
		display: block;
		position: fixed;
		z-index: 100;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		transition: transform 0.5s;
		transform: translateX(0%) translateY(-100%);
	}

	&.active .splash-icon {
		transform: translateX(0%) translateY(0%);

		&::after {
			box-sizing: border-box;
			position: absolute;
			left: 50%;
			display: block;
			border: 5px solid $splash-color;
			border-radius: 50%;
			content: '';
			top: 50%;
			margin-left: -20px;
			width: 40px;
			height: 40px;
			border-top-color: rgba(0, 0, 0, 0.2);
			animation: splash-spinner 1.2s linear infinite;
		}
	}
}

@keyframes splash-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
