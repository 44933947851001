.custom-loading-spinner {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgb(255 255 255 / 50%);
}
