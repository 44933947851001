/* JOST* */

@font-face {
	font-family: 'Jost';
	src: url(Jost-100.ttf) format('truetype');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-200.ttf) format('truetype');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-300.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-400.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-500.ttf) format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-600.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-700.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-800.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-900.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-100-Italic.ttf) format('truetype');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-200-Italic.ttf) format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-300-Italic.ttf) format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-400-Italic.ttf) format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-500-Italic.ttf) format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-600-Italic.ttf) format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-700-Italic.ttf) format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-800-Italic.ttf) format('truetype');
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Jost';
	src: url(Jost-900-Italic.ttf) format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
