// Info Popover
.info-popover {
	max-width: unset;
	.popover-body {
		max-width: unset !important;
	}
}

.custom-dialog-popover {
	width: 135px !important;

	.popover-body {
		padding: 6px !important;
	}

	.popover-body > div > form > input {
		padding-left: 2px !important;
		padding-right: 2px !important;
	}
}

.modal-backdrop.fade {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

.modal-backdrop {
	// don't remove and update this css this class directly using by modal
	width: 100%;
	height: 100%;
}
