.custom-zoom {
	cursor: pointer;
	transition: transform 1s;
}

.custom-zoom:hover {
	-ms-transform: scale(1.05); /* IE 9 */
	-webkit-transform: scale(1.05); /* Safari 3-8 */
	transform: scale(1.05);
}

// Card hover effect on home component cards
.card-active:hover {
	transform: translateY(-1%);
}

.card-active {
	cursor: pointer;
	transition: transform 0.25s ease-out;
}

// Experimental
/* Sweep To Bottom */
.hvr-sweep-to-bottom {
	transform: perspective(1px) translateZ(0);
	transition-property: color;
	transition-duration: 0.3s;
}
.hvr-sweep-to-bottom:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	content: '';
	position: absolute;
	background: #2098d1;
	border-radius: 0.5rem;
	transform: scaleY(0);
	transform-origin: 50% 0;
	transition-property: transform;
	transition-duration: 0.3s;
	transition-timing-function: ease-out;
}
.hvr-sweep-to-bottom:hover,
.hvr-sweep-to-bottom:focus,
.hvr-sweep-to-bottom:active {
	color: white;
}
.hvr-sweep-to-bottom:hover:before,
.hvr-sweep-to-bottom:focus:before,
.hvr-sweep-to-bottom:active:before {
	transform: scaleY(1);
}
