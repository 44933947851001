.accordion {
	.card:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.card-header {
		border-bottom: 0;
	}

	.card-body {
		border-top: 1px solid $card-border-color;
	}

	.card-title a {
		color: $body-color;
	}
}
