/**------------------------------------------------------
 * Scroll Bar (inspired by mac os)
 * -------------------------------
 * Links
 * - Examples	: https://codepen.io/devstreak/pen/dMYgeO,
 * - Infos		: https://css-tricks.com/the-current-state-of-styling-scrollbars/
 */
.scroll-bar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px #ffffff;
	border-radius: 10px;
	background-color: #ffffff;
}

.scroll-bar::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

.scroll-bar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #dddddd80;
}

.gray-scroll-bar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px #e9ecef;
	border-radius: 10px;
	background-color: #e9ecef;
}

.gray-scroll-bar::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

.gray-scroll-bar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #cbcbd490;
}
