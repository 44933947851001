.form-group {
	margin-bottom: 1rem;
}

.text-justify {
	text-align: justify;
}

.btn.btn-default {
	border-color: transparent !important;
}

.btn-group > .btn.dropdown-toggle-split:first-child {
	border-top-right-radius: $border-radius !important;
	border-bottom-right-radius: $border-radius !important;
}

.btn-group-sm > .btn,
.btn-sm,
.form-control-sm,
.form-select-sm {
	border-radius: 0.4rem;
}
