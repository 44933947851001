@mixin tab-variant($color) {
	.nav-tabs .nav-link.active {
		background: $color;
		border-bottom-color: $color;
		color: $white;
	}

	.tab-content {
		background: $color;
		color: $white;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		color: $white;
	}
}
