.form-checkbox .form-check-label::before {
	border-radius: 0.25rem !important;
}

.btn-group > .btn-light:first-of-type {
	border-bottom-left-radius: 0.5rem !important;
	border-top-left-radius: 0.5rem !important;
}

.btn-group > .btn-light:last-of-type {
	border-bottom-right-radius: 0.5rem !important;
	border-top-right-radius: 0.5rem !important;
}
