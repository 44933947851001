.avatar {
	width: 40px;
	height: 40px;
}

.avatar-title {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: $white;
}
