.min-vw-25 {
	min-width: 25vw !important;
}
.min-vw-50 {
	min-width: 50vw !important;
}
.min-vw-75 {
	min-width: 75vw !important;
}
.min-vh-25 {
	min-height: 25vh !important;
}
.min-vh-50 {
	min-height: 50vh !important;
}
.min-vh-75 {
	min-height: 75vh !important;
}

.vw-25 {
	width: 25vw !important;
}
.vw-50 {
	width: 50vw !important;
}
.vw-75 {
	width: 75vw !important;
}
.vh-25 {
	height: 25vh !important;
}
.vh-50 {
	height: 50vh !important;
}
.vh-75 {
	height: 75vh !important;
}
