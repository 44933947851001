/* stylelint-disable selector-class-pattern */

/** ------------------------------------------------------
 * Tooltip Style
 */
.tooltip-inner {
	z-index: 1080;
	text-align: justify;

	/* making the text not selectable */
	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
}

.ngbTooltip-small .tooltip-inner {
	max-width: 200px !important;
}

.ngbTooltip-medium .tooltip-inner {
	max-width: 350px !important;
}

.ngbTooltip-large .tooltip-inner {
	max-width: 500px !important;
}

/** ------------------------------------------------------
 * Fixing Side Effects / Bugs
 */

/* Note: This class is using to disable tooltip for drag-preview */
.cdk-drag-preview {
	.tooltip {
		display: none;
	}
}
