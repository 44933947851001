/**------------------------------------------------------
 * Disable the UI
 */
.disabled-container > :not(.exclude-disabled) {
	opacity: 0.5;
	pointer-events: none;
}

.disabled-container-blur > :not(.exclude-disabled) {
	filter: blur(2px);
	opacity: 0.5;
	pointer-events: none;
}

.disabled-page-container > :not(.exclude-disabled) {
	filter: blur(4px);
	pointer-events: none;
}

/**------------------------------------------------------
 * Free Version limitation
 */
.limit-by-blur {
	filter: blur(4px);
	pointer-events: none;
	@extend %no-user-select;
}

.limit-design-by-blur {
	filter: blur(7px);
	pointer-events: none;
	@extend %no-user-select;
}

.limit-chart-by-blur {
	filter: blur(5px);
	pointer-events: none;
	@extend %no-user-select;
}

.limit-by-disable {
	opacity: 0.4;
	pointer-events: none;
	@extend %no-user-select;
}
