.bg-dark {
	background: linear-gradient(
		var(--gradient-dark),
		var(--gradient-light)
	) !important;
}

.bg-theme {
	background: var(--content-background) !important;
}

.bg-primary,
.btn-primary {
	background: var(--content) !important;
	border-color: var(--content) !important;
}

.btn-dark {
	background: var(--content-background) !important;
	border-color: var(--content-background) !important;
}

.btn-outline-primary {
	color: var(--content) !important;
	border-color: var(--content) !important;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
	background-color: var(--content);
	border-color: var(--content);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: var(--content);
	border-color: var(--content);
	color: var(--white) !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: var(--content);
}

.btn-outline-warning:focus {
	color: var(--white);
}

.text-dark {
	color: var(--content) !important;
}

.text-primary {
	color: var(--content) !important;
}

// Dynamic Dropdown Background (based on theme)
.dropdown-item.active,
.dropdown-item:active {
	background-color: var(--content) !important;
}

.form-check-input:checked,
.form-check-input:checked {
	border-color: var(--content) !important;
	background-color: var(--content) !important;
}

.form-check-input:checked:focus,
.form-check-input:checked:focus {
	box-shadow:
		0 0 0 1px #fff,
		0 0 2px 0.2rem var(--content) !important;
}
