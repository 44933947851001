/** Breakpoint Definitions */
$breakpoints: (
	'phone': 599px,
	'tablet': 920px,
	'small-screen': 1280px,
	'normal-screen': 1920px,
	'big-screen': 3840px
) !default;

/**---------------------------------------------
 * Greater Than
 * => use : @include device-gt(normal-screen) {}
 */
@mixin device-gt($breakpoint) {
	// Does the breakpoint exists in the map?
	@if map-has-key($breakpoints, $breakpoint) {
		// Write the media query.
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		// Log a warning (If the breakpoint doesn't exist in the map)
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

/**---------------------------------------------
 * Less Than
 * => use : @include device-lt(normal-screen) {}
 */
@mixin device-lt($breakpoint) {
	// Does the breakpoint exist in the map?
	@if map-has-key($breakpoints, $breakpoint) {
		// Write the media query.
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}
	} @else {
		// Log a warning (If the breakpoint doesn't exist in the map)
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

/**---------------------------------------------
 * Respond Between
 * > use: @include device-bw(normal-screen, big-screen) {}
 */
@mixin device-bw($lower, $upper) {
	// Do both the lower and upper breakpoints exist in the map?
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper)
	{
		// Write the media query.
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}
	} @else {
		// Log a warning (If lower breakpoint is invalid)
		@if (map-has-key($breakpoints, $lower) == false) {
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}

		// Log a warning (If upper breakpoint is invalid)
		@if (map-has-key($breakpoints, $upper) == false) {
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}
