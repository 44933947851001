/** ------------------------------------------------------
 * Template SCSS/CSS of Spark
 */

// $enable-responsive-font-sizes: true;

/** Spark bootstrap styles */
@import '../../../../../domains/frontend/templates/spark/src/scss/app';

/** Ng wizard and toastr styles */
@import '../../../../../libs/frameworks/angular/src/components/containers/ng-wizard/themes/ng-wizard.scss';
@import '../../../../../libs/frameworks/angular/src/components/containers/ng-wizard/themes/ng-wizard-theme-arrows.scss';

/** Tooltip and modal styles */
@import '../../../../../libs/frameworks/angular/src/components/dialogs/tooltip/tooltip.scss';
@import '../../../../../libs/frameworks/angular/src/services/content-assistants/modal-assistant/modal-assistant.scss';

/** ------------------------------------------------------
 * Custom SCSS/CSS of Project
 */
@import './app/shared/directives/loading-spinner/loading-spinner.scss';
